/*
  Reset some basic elements
*/

body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/*
  Basic styling
*/

/* Headings */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: bold;
  line-height: 1.25;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2rem;
}
h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}
h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
}
h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

details {
  margin: 0.5rem auto;
  padding: 0.5rem 0.5rem;
  width: 100%;
  max-width: calc(100% - 1rem);
  position: relative;
  border: 1px solid #e0e4e8;
  border-radius: 0px;
  background-color: #f5f5f5;
  color: #263238;
  transition: background-color .15s;
  
  > :last-child {
    margin-bottom: 1rem;
  }
  
  &::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    opacity: .15;
    pointer-events: none;
    transition: opacity .2s;
    z-index: -1;
  }
  
  &[open] {
    background-color: #FFF;
    
    &::before {
      opacity: .6;
    }
  }
}

summary {
  padding: 0.1rem 2em 0.1rem 0;
  display: block;
  position: relative;
  font-size: 1.25em;
  font-weight: bold;
  cursor: pointer;
  
  &::before,
  &::after {
    width: .75em;
    height: 2px;
    position: absolute;
    top: 50%;
    right: 0;
    content: '';
    background-color: currentColor;
    text-align: right;
    transform: translateY(-50%);
    transition: transform .2s ease-in-out;
  }
  
  &::after {
    transform: translateY(-50%) rotate(90deg);
    
    [open] & {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  
  &::-webkit-details-marker {
    display: none;
  }
}



/* Body */
body {
  font-family: $base-font-family;
}
body img {
    align: middle;
    margin: 5px auto auto auto;
    display: block;
    max-width: 100%;
    width: auto;
}

p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.65;
  font-family: $body-font-family;
}

/* Lists */
ul, ol, dl {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  font-family: $body-font-family;
}

ol li {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
}

li {
  margin-bottom: 0.7rem;
}

dt {
  font-weight: bold;
}
dd {
  margin-bottom: .5rem;
}

/*
  Links
*/

a {
  color: $brand-color;
  text-decoration: none;
}

/*
  Blockquote
*/

blockquote {
  margin: 10px 20px 10px;
  padding: 0px 15px;
  border-left: 0.25em solid #ccc;
  color: #999;
  line-height: 1.5;
}

/**
 * Tables
 */
 table {
     border-collapse: collapse;
     margin-bottom: 30px;
     width: 100%;
 }

 table, th, td {
     border: 1px solid black;
 }
 th, td {
     padding: 15px;
     text-align: left;
 }

 hr {
  border: 1px solid #e0e4e8;
}

/*
  Forms
*/
input[type=text], input[type=email], select, textarea {
  width: 100%;
  padding: 8px;
  border: 2px solid #e0e4e8;
  box-sizing: border-box;
}

input[type=submit] {
  background-color: #e0e4e8;
  padding: 8px;
  border: none;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #c5cdd4;
}