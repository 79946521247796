/*
  Site variables
*/

$base-font-family: 'Open Sans', sans-serif;

$body-font-family: 'Open Sans', sans-serif;

$code-font-family: "Source Code Pro", monospace;

$brand-color: black;

$container-width: 820px;

$tablet-width: 820px;

$phone-width: 480px;

$header-thickness: 56px;

@import "_sass/-sections-dir";
