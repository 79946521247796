$icon-transition-time: 0.3s;
$house-color: #801818;
$bell-color: #f39c12;
$rss-color: #ff6600;
$github-color: #343434;
$twitter-color: #00aced;
$instagram-color: #375989;
$facebook-color: #3d5b99;
$linkedin-color: #0073a4;
$pinterest-color: #bd081c;
$youtube-color: #FF0000;
$reddit-color: #FF4500;

@mixin social-media-icon($color, $transition){
  -webkit-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
  &:hover{
    color: $color;
  }
}

.bi-house-fill {
  padding: 5px;
  @include social-media-icon($house-color, $icon-transition-time);
}

.bi-bell-fill {
  padding: 5px;
  @include social-media-icon($bell-color, $icon-transition-time);
}

.bi-rss-fill {
  padding: 5px;
  @include social-media-icon($rss-color, $icon-transition-time);
}

.bi-github {
  padding: 5px;
  @include social-media-icon($github-color, $icon-transition-time);
}

.bi-twitter {
  padding: 5px;
  @include social-media-icon($twitter-color, $icon-transition-time);
}

.bi-instagram {
  padding: 5px;
  @include social-media-icon($instagram-color, $icon-transition-time);
}

.bi-facebook {
  padding: 5px;
  @include social-media-icon($facebook-color, $icon-transition-time);
}

.bi-linkedin {
  padding: 5px;
  @include social-media-icon($linkedin-color, $icon-transition-time);
}

.bi-pinterest {
  padding: 5px;
  @include social-media-icon($pinterest-color, $icon-transition-time);
}

.bi-youtube {
  padding: 5px;
  @include social-media-icon($youtube-color, $icon-transition-time);
}

.bi-reddit {
  padding: 5px;
  @include social-media-icon($reddit-color, $icon-transition-time);
}
