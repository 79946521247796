/* open-sans-regular - latin-ext */
@font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 400;
     src: url('/assets/fonts/open-sans/open-sans-v34-latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('/assets/fonts/open-sans/open-sans-v34-latin-ext-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }

/* source-code-pro-regular - latin-ext */
@font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Source Code Pro';
     font-style: normal;
     font-weight: 400;
     src: url('/assets/fonts/source-code-pro/source-code-pro-v14-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('/assets/fonts/source-code-pro/source-code-pro-v14-latin-ext_latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
